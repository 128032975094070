import { useMemo } from "react";
import { updateProfile as updateProfileRoutine } from "routines/profile";

import parseBoolString from "utils/misc/parseBoolString";

import useActionCreators from "hooks/useActionCreators";
import { useLocalStorage } from "hooks/useLocalStorage";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import useQuery from "hooks/useQuery";

export default function useFrontendSetting(
  key,
  defaultValue,
  withCookie = true
) {
  const isLoggedIn = useLoggedIn();
  const user = useLoggedInUser();
  const { updateProfile } = useActionCreators({
    updateProfile: updateProfileRoutine,
  });

  const [query] = useQuery();

  const [localStorageValue, setLocalStorageValue] = useLocalStorage(key, {
    withCookie,
  });

  const setter = (newValue) => {
    if (isLoggedIn) {
      updateProfile({
        meta_data: {
          frontend_flags: {
            [key]: newValue,
          },
        },
      });
    } else {
      setLocalStorageValue(newValue);
    }
  };

  const returnValue = useMemo(() => {
    if (query && query[key]) {
      return parseBoolString(query[key], true);
    }

    if (isLoggedIn) {
      return user.getIn(["frontend_flags", key]) || defaultValue;
    }

    return localStorageValue || defaultValue;
  }, [defaultValue, isLoggedIn, key, localStorageValue, query, user]);

  return [returnValue, setter];
}
