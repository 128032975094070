import { useContext } from "react";
import Cookies from "universal-cookie";

import RequestContext from "pages/RequestContext";

import { LOCALSTORAGE_TOKEN_KEY } from "constants/base";

export default function useCookie() {
  const { server, hasAuthCookieDefined } = useContext(RequestContext);

  const cookies = new Cookies();

  const clientCookie = cookies.get(LOCALSTORAGE_TOKEN_KEY);

  return server ? hasAuthCookieDefined : !!clientCookie;
}
